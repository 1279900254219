<template>
  <div class="container" v-if="!!user">
    <section v-if="isBlocked" class="h-100 d-flex">
      <div class="jumbotron m-auto w-100">
        <h5>
          <label>لا يمكن تفعيل الحساب تم تعليقه من قبل الادارة</label>
          <label class="pl-1" v-if="user.blocker">
            <label>بوساطة</label>
            <label class="text-primary pl-1">{{ user.blocker.name }}</label>
          </label>
        </h5>
        <button class="btn btn-info rounded-0" v-if="superAdmin" data-toggle="modal" data-target="#unblock">
          فك التعليق
        </button>
      </div>

      <teleport to="#app">
        <confirm-model
            title="فك التعليق"
            target-id="unblock"
            @confirmed="unblock"
        >
          <slot>
            <label>هل أنت متأكد من فك تعليق المستخدم</label>
            <label class="pl-1">{{ user.name }}</label>
            <label class="pl-1">؟</label>
          </slot>
        </confirm-model>
      </teleport>
    </section>

    <section v-else>
      <div class="row" v-if="!!statusText">
        <div class="col-sm-12">
          <label>
            <span class="mr-1">التأمين الحالي:</span>
            <span class="link danger mr-1">{{ insurance || "لا يوجد" }}</span>
            <span v-if="!!insurance">دينار</span>
          </label>
          <label class="mx-2">/</label>
          <label>
            <span>حالة المستخدم: </span>
            <span class="link danger">{{ statusText }}</span>
          </label>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>الإسم</th>
                <th>الحالة</th>
                <th>تعديل</th>
              </tr>
              <tr v-for="status in accountStatus" :key="status.title">
                <td class="pm-1">{{ status.title }}</td>
                <td class="pm-1">
                  <strong :class="status.class">{{ status.content }}</strong>
                </td>
                <td class="pm-1">
                  <button
                      :disabled="status.disabled"
                      @click="setStatusModal(status)"
                      type="button"
                      class="btn btn-sm btn-primary nr-shadow"
                      :title="status.title"
                  >
                    <i class="fas fa-pen status-button"></i>
                  </button>
                </td>
              </tr>
            </table>

            <confirm-model
                v-if="!!selectedStatus"
                :title="userStatusTitle"
                target-id="change-user-status"
                :centered="true"
                confirm-text="حفظ"
                @confirmed="statusConfirmed"
            >
              <form id="user-status-form">
                <p
                    class="text-left font-weight-bolder"
                    v-if="selectedStatus.type === 1"
                >
                  تغيير حالة رقم الهاتف الرئيسي
                </p>
                <FormImage
                    v-if="selectedStatus.type === 3"
                    ref="insurance_image"
                    id="insurance_image"
                    text="تحميل صورة الوصل"
                />
                <hr v-if="selectedStatus.type === 3"/>
                <p class="text-left">يرجى التحديد</p>
                <bootstrap-select
                    id="status"
                    :options="userStatusOptions"
                    :empty-option="false"
                ></bootstrap-select>
                <input type="hidden" name="user" :value="userId"/>
              </form>
            </confirm-model>
          </div>
        </div>
      </div>

      <div v-if="superAdmin">
        <hr/>
        <div>
          <h5>تعليق حساب المستخدم</h5>
          <p>عند تعليق حساب المستخدم فانه لن يتمكن المساعد من تفعيل حسابه الا اذا تم اعادة تفعيله من قبل حساب الادمن
            فقط, كما انه لن يتمكن المستخدم من تسجيل الدخول وستظهر له رسالة تم تعليق الحساب عند ادخاله لاسم المتسخدم
            وكلمة المرور, كما ان هذا الإجراء غير متاح فقط للادمن.</p>
          <button class="btn btn-secondary rounded-0" data-toggle="modal" data-target="#block">
            تعليق الحساب
          </button>
        </div>

        <hr/>
        <div>
          <h5>نحديث الحساب لدى المستخدم</h5>
          <p>في حال لم يتم تحديث حساب المستخدم بشكل تلقائي يمكنك بالضغط على تحديث الحساب لدى المستخدم
            دون طلب المستخدم تسجيل الخروج والدخول ثانيةً</p>
          <button class="btn btn-info rounded-0" @click="refreshUser">
            تحديث الحساب
          </button>
        </div>

        <hr/>
        <div>
          <h5>حذف الحساب</h5>
          <p>
            عند تأكيد حذف الحساب سوف يتم حذف الحساب نهائياً
          </p>
          <button class="btn btn-danger rounded-0" data-toggle="modal" data-target="#delete">
            حذف الحساب
          </button>
        </div>

        <teleport to="#app">
          <confirm-model
              confirm-btn="btn-danger"
              cancel-btn="btn-info"
              header-bg="bg-danger"
              title="تعليق الحساب"
              target-id="block"
              @confirmed="block"
          >
            <slot>
              <label>هل أنت متأكد من تعليق حساب المستخدم</label>
              <label class="pl-1 text-primary">{{ user.name }}</label>
              <label class="pl-1">؟</label>
            </slot>
          </confirm-model>

          <confirm-model
              target-id="delete"
              title="حذف الحساب"
              confirm-text="حذف"
              confirm-btn="btn-danger"
              cancel-btn="btn-info"
              @confirmed="deleteUser">
      <slot>هل أنت متأكد من حذف حساب المستخدم ؟</slot>
    </confirm-model>
        </teleport>
      </div>

      <teleport to="#app">
        <confirm-model
            confirm-btn="hide"
            cancel-btn="btn-info"
            cancel-text="اغلاق"
            header-bg="bg-info"
            :modal-lg="true"
            title="لا يمكن الغاء تفعيل المستخدم"
            target-id="user_auctions"
        >
          <slot>
            <h5 class="mb-5">لم يتم التعديل, لدى المستخدم مزاد حالي او عليه رسوم احد المزادات التالية</h5>

            <div class="mb-3" v-if="hasCurrentAuctions">
              <h6>المزادات الحالية:</h6>
              <ul>
                <li class="link danger" v-for="auction in userAuctions.current"
                    :key="`current_auction_${auction.id}`">
                  <router-link
                      target="_blank"
                      :to="{ name: 'auctions.status', params: {auction: auction.slug} }"
                      class="link danger"
                  >
                    {{ auction.name }}
                  </router-link>
                </li>
              </ul>
              <hr/>
            </div>

            <div class="mb-3" v-if="hasStumbledAuctions">
              <h6>المزادات المتعثرة:</h6>
              <ul>
                <li class="link danger" v-for="auction in userAuctions.current"
                    :key="`stumbled_auction_${auction.id}`">
                  <router-link
                      target="_blank"
                      :to="{ name: 'auctions.status', params: {auction: auction.slug} }"
                      class="link danger"
                  >
                    {{ auction.name }}
                  </router-link>
                </li>
              </ul>
              <hr/>
            </div>

            <div class="mb-3" v-if="hasUnpaidAuctions">
              <h6>مزادات بحاجة الى تسديد:</h6>
              <ul>
                <li class="link danger" v-for="auction in userAuctions.unpaid" :key="`unpaid_auction_${auction.id}`">
                  <router-link
                      target="_blank"
                      :to="{ name: 'auctions.status', params: {auction: auction.slug} }"
                      class="link danger"
                  >
                    {{ auction.name }}
                  </router-link>
                </li>
              </ul>
              <hr/>
            </div>

            <div class="mb-3"
                 v-if="hasUnpaidFloorAuctions">
              <h6>ارضيات غير مدفوعة:</h6>
              <ul>
                <li v-for="auction in userAuctions.unpaid_floor" :key="`unpaid_floor_auction_${auction.id}`">
                  <router-link
                      target="_blank"
                      :to="{ name: 'auctions.status', params: {auction: auction.slug} }"
                      class="link danger"
                  >
                    {{ auction.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </slot>
        </confirm-model>
      </teleport>
    </section>
  </div>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import FormImage from "@/components/Forms/FormImage";
import files_mixin from "@/mixins/files_mixin";
import types from "@/common/types";
import {mapGetters} from "vuex";
import arrays from "@/common/arrays";

export default {
  components: {FormImage, BootstrapSelect, ConfirmModel},
  mixins: [files_mixin],
  data() {
    return {
      user: "",
      userStatusTitle: "",
      selectedStatus: "",
      userStatusOptions: [],
      userAuctions: {},
    };
  },
  computed: {
    ...mapGetters(['superAdmin']),
    userId() {
      const route = this.$route;
      return !!route ? route.params.user : "";
    },
    insurances() {
      return this.$store.getters.insurances;
    },
    hasInsurance() {
      return !(!this.user || !this.user.insurance);
    },
    insurance() {
      return this.hasInsurance ? this.user.insurance.price : "";
    },
    success() {
      return "text-success";
    },
    danger() {
      return "text-danger";
    },
    mobileStatus() {
      const check = !(!this.user || !this.user.primary_mobile || this.user.primary_mobile.verified !== 'y');
      return {
        check,
        type: 1,
        url: "/mobile/verify",
        title: "الهاتف",
        content: check ? "مؤكد" : "غير مؤكد",
        class: check ? this.success : this.danger,
        disabled: this.stumbledStatus.check,
        options: [
          {content: "تأكيد", id: "y"},
          {content: "إلغاء التأكيد", id: "n"},
        ],
      };
    },
    insuranceStatus() {
      const check = !(!this.user || !types.checkY(this.user.add_insurance));
      return {
        check: check,
        type: 2,
        url: "user/verify",
        title: "التوثيق",
        content: check ? "موثق" : "غير موثق",
        class: check ? this.success : this.danger,
        disabled: !this.mobileStatus || !this.mobileStatus.check,
        options: [
          {content: "توثيق", id: "y"},
          {content: "إلغاء التوثيق", id: "n"},
        ],
      };
    },
    activatedStatus() {
      const check = !(!this.user || !this.user.insurance_id);
      return {
        check,
        type: 3,
        url: "/user/activation",
        title: "التفعيل",
        content: check ? "مفعل" : "غير مفعل",
        class: check ? this.success : this.danger,
        disabled:
            !this.insuranceStatus ||
            this.insuranceStatus.disabled ||
            !this.insuranceStatus.check,
        options: this.insurances,
      };
    },
    shouldUpdateStatus() {
      const check = !(
          !this.user || !types.checkY(this.user.should_update_data)
      );
      return {
        check,
        type: 4,
        url: "/user/should-update",
        title: "تحديث بياناته",
        content: check ? "مطلوب" : "غير مطلوب",
        class: check ? this.danger : this.success,
        disabled: false,
        options: [
          {content: "طلب", id: "y"},
          {content: "إلغاء الطلب", id: "n"},
        ],
      };
    },
    stumbledStatus() {
      const check = !!this.user && !!this.user.status && parseInt(this.user.status) === 1;
      return {
        check,
        type: 5,
        url: "/user/stumble",
        title: "الحجز",
        content: check ? "محجوز" : "غير محجوز",
        class: !check ? this.success : this.danger,
        disabled: false,
        options: [
          {content: "حجز", id: "y"},
          {content: "إلغاء الحجز", id: "n"},
        ],
      };
    },
    accountStatus() {
      return [
        this.mobileStatus,
        this.insuranceStatus,
        this.activatedStatus,
        this.shouldUpdateStatus,
        this.stumbledStatus,
      ];
    },
    statusText() {
      const status = !!this.user ? this.user.status : "";
      if (!status) return "";
      switch (parseInt(status)) {
        case 1:
          return "متعثر";
        case 2:
          return "يحتاج تأكيد الهاتف";
        case 3:
          return "يحتاج تحديث بياناته";
        case 4:
          return "يحتاج تفعيل";
        case 5:
          return "مفعل";
        default:
          return "";
      }
    },
    isBlocked() {
      return !this.user || this.user.blocked_at
    },
    hasCurrentAuctions() {
      return this.userAuctions && this.userAuctions.current && this.userAuctions.current.length > 0
    },
    hasStumbledAuctions() {
      return this.userAuctions && this.userAuctions.current && this.userAuctions.stumbled.length > 0
    },
    hasUnpaidAuctions() {
      return this.userAuctions && this.userAuctions.current && this.userAuctions.unpaid.length > 0
    },
    hasUnpaidFloorAuctions() {
      return this.userAuctions && this.userAuctions.current && this.userAuctions.unpaid_floor.length > 0
    }
  },
  methods: {
    async setStatus() {
      if (!this.userId) {
        return http.popupMessage("error", "لم يتم تحديد المستخدم");
      }

      const response = await http.send("user/status", {
        _method: "GET",
        user: this.userId,
      });

      if (http.responseAccepted(response)) {
        this.setStatusData(response.data);
      }
    },
    async setStatusModal(status) {
      this.userStatusTitle = await status.title;
      this.selectedStatus = await status;
      this.userStatusOptions = await status.options;
      $_("#status").selectpicker("refresh");
      this.showModal("#change-user-status");
    },
    async statusConfirmed() {
      if (!this.selectedStatus) {
        return http.popupMessage("error", "يرجى تحديد حالة الحساب");
      }

      if (!$_("#status").val()) {
        return http.popupMessage("error", "لم يتم تحديد الحالة");
      }

      if (this.selectedStatus.type === 3 && this.fileIsEmpty("insurance_image")) {
        return http.popupMessage("error", "يرجى تحميل صورة الوصل");
      }

      const formData = new FormData($_("#user-status-form")[0]);
      const response = await http.send(this.selectedStatus.url, formData, {headers: {'Content-Type': 'multipart/form-data'}});

      if (http.responseAccepted(response)) {
        this.setStatusData(response.data);
        this.hideModal("#change-user-status");
        this.reset();

        if (!!response.data.msg) {
          http.popupMessage("success", response.data.msg);
        } else if (!!response.data.has_auctions) {
          // this.selectedStatus
          this.userAuctions = response.data.auctions
          this.showModal('#user_auctions')
          // http.errorMessage(response.data.error_msg);
        } else {
          http.popupMessage("success", messages.updated);
        }
      }
    },
    setStatusData(data) {
      if (!data) {
        return;
      }

      if (!!data.user) {
        this.user = data.user;
      }

      if (!!data.insurances) {
        this.$store.dispatch("setInsurancesAction", data.insurances);
      }
    },
    reset() {
      this.emptyField("#status");

      const insurance_image = this.$refs.insurance_image;
      if (!!insurance_image) {
        insurance_image.clearInputFile("insurance_image");
      }
    },
    async block() {
      const response = await http.send("user/block", {
        user: this.userId,
      });

      if (http.responseAccepted(response)) {
        this.user.blocked_at = response.data.blocked_at
        this.user.blocker = response.data.blocker
      }

      this.hideModal("#block");
    },
    async unblock() {
      const response = await http.send("user/unblock", {
        user: this.userId,
      });

      if (http.responseAccepted(response)) {
        this.user.blocked_at = null
        this.user.blocker = null
      }

      this.hideModal("#unblock");
    },
    async refreshUser() {
      const response = await http.send("user/refresh-data", {
        user: this.userId,
      });

      http.popupMessage("success", messages.updated);
    },
    async deleteUser() {
      if (!!this.user) {
        await http.send(`users/${this.user.id}`, {
          _method: "DELETE",
        });

        http.popupMessage("success", "تم حذف المستخدم بنجاح");
        await this.$router.replace({name: 'users.list'})
      }
    }
  },
  async created() {
    await this.setStatus();
  },
};
</script>

<style scoped>
.status-button {
  font-size: 0.9rem;
}
</style>
